body {
  margin: 10px 20px;
}

.App {
  text-align: left;
  font-size: 18px;
}

div[class^="Nav__nav"] {
  padding: 0px 10px;
}

.AppContent {
  margin: 0px 20px;
}

.App-logo {
  height: 4vmin;
  pointer-events: none;
  margin-left: 30px;
  margin-right: 30px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 15s linear;
  }
}

.App-header {
  margin: 0px 15px;
  background-color: #dddddd;
  /* color: white; */
  min-height: 7vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  /*
  justify-content: center;
  */
}

.App-header-title {
  font-size: calc(10px + 2vmin);
  color: #111111;
}

.App-header button {
  margin-left: 60px;
}

.TextEntry {
  margin: 6px 0px;
  border: 1px solid rgb(231, 231, 231);
  padding: 5px 10px;
}

.TextEntry label {
  width: 140px;
}

.TextEntry label:after {
  content: ":";
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
